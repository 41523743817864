* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

.wrapper {
  height: 100%;
  width: 100%;
}

.page {
  height: 100%;
  width: 100%;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.content {
  z-index: 2;
  box-sizing: border-box;
  overflow-y: auto;
  text-align: center;
  color: #f5deb4;
  font: 20px/30px Consolas;
  border: 5px solid white;
  padding: 15px;
  background-color: rgba(212, 193, 193, 0.2);
  transition: 0.1s all ease-in-out;
}
.content:hover {
  padding: 10px;
  background-color: rgba(212, 193, 193, 0.1);
}

.header {
  z-index: 2;
  height: 40px;
  width: 100%;
  padding-top: 10px;
  color: #f5deb4;
  font: 20px/30px Consolas;
  /*.header-d {
  }*/
}
.header .fa-home {
  margin-left: 10px;
}
.header .link-1 {
  text-align: left;
  color: white;
}

.info-button {
  float: right;
  margin-right: 10px;
  border-radius: 50%;
  background-color: salmon;
  background-repeat: no-repeat;
  color: white;
  font-size: 32px;
  border: none;
  cursor: pointer;
  text-align: center;
  /*&:hover {
  }*/
}

.footer {
  z-index: 2;
  height: 30px;
  text-align: center;
  padding-bottom: 10px;
  color: #f5deb4;
  font: 20px/30px Consolas;
}

.back-loop {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: inherit;
  height: inherit;
  transform: scale(1.23);
  object-fit: cover;
}

.fa-code {
  color: rebeccapurple;
}

.fa-heart {
  color: red;
}

.fa-telegram-plane {
  color: seashell;
}

.fa-terminal {
  color: green;
}

.fa-file-archive {
  color: brown;
}

.fa-youtube {
  color: firebrick;
}

.fa-home {
  color: antiquewhite;
}

.link-1:link {
  color: lightsalmon;
  text-decoration: none;
}
.link-1:hover {
  color: sandybrown;
  text-decoration: underline;
}
.link-1:visited {
  color: lightsalmon;
}
.link-1:active {
  color: rosybrown;
}

.description-1 {
  font: 12px/30px Consolas;
  color: lightgray;
}

.description-2 {
  font: 12px/30px Consolas;
  color: gray;
  text-align: right;
}

.description-4 {
  font: 12px Consolas;
  color: gray;
}

@media screen and (orientation: landscape) {
  .description-1 {
    margin: 4px;
  }

  .description-2 {
    margin: 4px;
  }

  p {
    margin: 8px;
  }

  .content .link-1 {
    margin: 4px;
  }
}
@media screen and (min-width: 360px) and (max-width: 850px) and (max-height: 850px) {
  .content {
    margin: 10px;
    overflow-y: scroll;
    max-height: calc(100% - 60px);
  }

  .description-2 {
    text-align: center;
  }
}
@media screen and (min-height: 320px) and (max-height: 600px) {
  .content {
    margin: 10px;
    overflow-y: scroll;
    max-height: calc(100% - 60px);
  }
}
@media screen and (min-height: 320px) and (max-height: 600px) and (orientation: landscape) {
  .content {
    margin: 10px;
    overflow-y: scroll;
    max-height: calc(100% - 60px);
  }
}